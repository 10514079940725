<template>
  <b-modal
    id="modal-edit-widget-name"
    :visible="visible"
    hide-footer
    centered
    size="lg"
    @change="$emit('change', false)"
    @show="openModal"
  >
    <template #modal-header>
      <language-selector-header
        :title="item.key ? $t('elements.edit') : $t('elements.create')"
        @closeModal="closeModal"
        @selectLanguage="(language) => (selectedLanguage = language)"
      />
    </template>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form @submit.prevent="handleSubmit(handleUpdatedTagline)">
        <validation-provider #default="validationContext" name=" ">
          <b-form-group :label="$t('layouts.widget-name')" label-for="name">
            <b-form-input
              id="name"
              v-model="customName[selectedLanguage]"
              type="text"
              maxlength="150"
              :disabled="isSaving"
              :placeholder="$t('layouts.widget-name')"
              :state="getValidationState(validationContext)"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <validation-provider #default="validationContext" name=" ">
          <b-form-group :label=" $t('layouts.widget-tagline')" label-for="name">
            <b-form-input
              id="name"
              v-model="appDescription[selectedLanguage]"
              type="text"
              maxlength="150"
              :disabled="isSaving"
              :placeholder="$t('layouts.widget-name')"
              :state="getValidationState(validationContext)"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <b-button
          variant="primary"
          class="float-right"
          :disabled="isSaving"
          type="submit"
        >
          <span
            v-if="isSaving"
            class="d-flex"
          ><b-spinner
             class="mr-1"
             small
             variant="white"
             label="Loading..."
           />
            {{ $t("form.actions.save") }}
          </span>
          <span v-else>{{ $t("form.actions.save") }}</span>
        </b-button>
      </b-form>

    <!-- Name -->
    <!--     {{ $t('layouts.widget-name') }}
    <form-translation-table
      v-model="customName"
      :value="computedAppNames"
      class="mt-50 mb-3"
      :value-placeholder="$t('layouts.widget-name')"
      label-attribute="name"
      value-attribute="text"
      @input="handleTitleChange"
    /> -->
    <!-- Tagline -->
    <!--     {{ $t('layouts.widget-tagline') }}
    <form-translation-table
      id="app-description"
      v-model="appDescription"
      class="mt-50"
      label-attribute="description"
      value-attribute="text"
      :value-placeholder="$t('layouts.widget-tagline')"
      :name="$t('organizations.edit.description.name')"
      :placeholder="$t('organizations.edit.description.placeholder')"
    /> -->
    </validation-observer>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import formValidation from '@core/comp-functions/forms/form-validation';
import { required } from '@validations';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import LanguageSelectorHeader from '@core/components/modal/LanguageSelectorHeader.vue';
import { randomString } from '@core/utils/utils';

export default {
  name: 'CustomAddonNameModal',
  components: {
    ValidationProvider,
    ValidationObserver,
    LanguageSelectorHeader,
  },
  mixins: [ToastNotificationsMixin],
  model: {
    prop: 'visible',
    event: 'change',
  },
  props: {
    item: { type: Object, required: true },
    editingType: { type: Object, default: () => {} },
    visible: Boolean,
    type: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isSaving: false,
      required,
      selectedLanguage: '',
      customName: {},
      appDescription: {},
      widgetKey: randomString(10),

    };
  },
  setup() {
    const { getValidationState } = formValidation(() => {});
    return { getValidationState };
  },
  computed: {
    availableLanguages() {
      return this.$store.getters.currentCollective?.availableLanguages;
    },
  },
  methods: {
    openModal() {
      this.availableLanguages.forEach((language) => {
        if (this.editingType && Object.values(this.editingType).length > 0) {
          if (this.editingType.customizations && this.editingType.customizations[language]) {
            this.customName[language] = this.editingType.customizations[language];
          } else if (this.editingType.name[language] && this.editingType.name[language].length > 0) {
            this.customName[language] = this.editingType.name[language];
          } else {
            this.customName[language] = this.item.name[language];
          }
        } else if (this.item.customizations && this.item.customizations.customizationName && this.item.customizations.customizationName[language]) {
          this.customName[language] = this.item.customizations.customizationName[language];
        } else if (this.item.customizations && this.item.customizations[language]) {
          this.customName[language] = this.item.customizations[language];
        } else {
          this.customName[language] = this.item.name[language];
        }
        if (this.item.tagline) {
          if (this.item.tagline[language]) {
            this.appDescription[language] = this.item.tagline[language];
          } else {
            this.appDescription[language] = this.item.tagline[0];
          }
        } else {
          this.appDescription[language] = '';
        }
      });
    },
    closeModal() {
      this.appDescription = {};
      this.customName = {};
      /*       this.$emit('closeModal');
 */ this.$bvModal.hide('modal-edit-widget-name');
    },
    async handleUpdatedTagline() {
      this.isSaving = true;
      const ob = {};
      if (this.type) {
        ob[this.type] = this.customName;
      } else {
        ob.customizationName = this.customName;
      }

      const response = await this.$store.dispatch('changeTaglineApp', {
        appID: this.appID ? this.appID : null,
        appKey: this.item.key,
        tagline: this.appDescription,
        customization: { ...this.item.customizations, ...ob },
      });

      if (this.item.customizations != null) {
        this.item.customizations.customizationName = { ...this.customName };
      }

      this.widgetKey = randomString(10);
      this.closeModal();
      this.isSaving = false;
      return response;
    },
    /*     handleTitleChange(customNameTable) {
      this.customName = customNameTable;
      this.$store.commit(ENABLED_APPS_MUTATIONS.updateAppCustomName, {
        appId: this.appId,
        customAppId: this.id,
        customNameTable,
      });
    }, */
  },

};
</script>

<style>

</style>
